@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-search-ic: "\EA01"
$icon-arrow-to-down: "\EA02"
$icon-lc-hero: "\EA03"
$icon-like-img: "\EA04"
$icon-video: "\EA05"
$icon-articles-img: "\EA06"
$icon-yt: "\EA07"
$icon-arrow-right: "\EA08"
$icon-play-video: "\EA09"
$icon-calendar: "\EA10"
$icon-time: "\EA11"
$icon-comments: "\EA12"
$icon-share: "\EA13"
$icon-fb-ms: "\EA14"
$icon-fb: "\EA15"
$icon-instagram: "\EA16"
$icon-telegram: "\EA17"
$icon-youtube: "\EA18"
$icon-close: "\EA19"
$icon-check: "\EA20"
$icon-close-pass: "\EA21"
$icon-open-pass: "\EA22"
$icon-arrow-left: "\EA23"
$icon-pdf: "\EA25"
$icon-slider-left: "\EA26"
$icon-slider-right: "\EA27"
$icon-comment: "\EA28"
$icon-checked: "\EA29"
$icon-alert-outline: "\EA30"
$icon-google: "\EA31"
$icon-vk: "\EA32"
$icon-twitter: "\EA33"
$icon-locked: "\EA34"
$icon-comment-multiple-outline: "\EA35"
$icon-personal-alert: "\EA36"
$icon-personal-arrow-message: "\EA37"
$icon-personal-contact: "\EA38"
$icon-personal-courses: "\EA39"
$icon-personal-hero: "\EA40"
$icon-personal-library: "\EA41"
$icon-personal-message: "\EA42"
$icon-personal-subsription: "\EA43"
$icon-personal-trainings: "\EA44"
$icon-personal-transactions: "\EA45"
$icon-personal-logout: "\EA46"
$icon-info: "\EA47"
$icon-copy: "\EA48"
$icon-trash: "\EA49"
$icon-alert: "\EA50"
$icon-link-variant: "\EA51"
$icon-link-copy: "\EA52"
$icon-link-solid: "\EA53"
$icon-simulation-icon: "\EA54"
$icon-edit-icon: "\EA55"
$icon-preloader: "\EA56"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-search-ic:before
	content: $icon-search-ic
.icon-arrow-to-down:before
	content: $icon-arrow-to-down
.icon-lc-hero:before
	content: $icon-lc-hero
.icon-like-img:before
	content: $icon-like-img
.icon-video:before
	content: $icon-video
.icon-articles-img:before
	content: $icon-articles-img
.icon-yt:before
	content: $icon-yt
.icon-arrow-right:before
	content: $icon-arrow-right
.icon-play-video:before
	content: $icon-play-video
.icon-calendar:before
	content: $icon-calendar
.icon-time:before
	content: $icon-time
.icon-comments:before
	content: $icon-comments
.icon-share:before
	content: $icon-share
.icon-fb-ms:before
	content: $icon-fb-ms
.icon-fb:before
	content: $icon-fb
.icon-instagram:before
	content: $icon-instagram
.icon-telegram:before
	content: $icon-telegram
.icon-youtube:before
	content: $icon-youtube
.icon-close:before
	content: $icon-close
.icon-check:before
	content: $icon-check
.icon-close-pass:before
	content: $icon-close-pass
.icon-open-pass:before
	content: $icon-open-pass
.icon-arrow-left:before
	content: $icon-arrow-left
.icon-pdf:before
	content: $icon-pdf
.icon-slider-left:before
	content: $icon-slider-left
.icon-slider-right:before
	content: $icon-slider-right
.icon-comment:before
	content: $icon-comment
.icon-checked:before
	content: $icon-checked
.icon-alert-outline:before
	content: $icon-alert-outline
.icon-google:before
	content: $icon-google
.icon-vk:before
	content: $icon-vk
.icon-twitter:before
	content: $icon-twitter
.icon-locked:before
	content: $icon-locked
.icon-comment-multiple-outline:before
	content: $icon-comment-multiple-outline
.icon-personal-alert:before
	content: $icon-personal-alert
.icon-personal-arrow-message:before
	content: $icon-personal-arrow-message
.icon-personal-contact:before
	content: $icon-personal-contact
.icon-personal-courses:before
	content: $icon-personal-courses
.icon-personal-hero:before
	content: $icon-personal-hero
.icon-personal-library:before
	content: $icon-personal-library
.icon-personal-message:before
	content: $icon-personal-message
.icon-personal-subsription:before
	content: $icon-personal-subsription
.icon-personal-trainings:before
	content: $icon-personal-trainings
.icon-personal-transactions:before
	content: $icon-personal-transactions
.icon-personal-logout:before
	content: $icon-personal-logout
.icon-info:before
	content: $icon-info
.icon-copy:before
	content: $icon-copy
.icon-trash:before
	content: $icon-trash
.icon-alert:before
	content: $icon-alert
.icon-link-variant:before
	content: $icon-link-variant
.icon-link-copy:before
	content: $icon-link-copy
.icon-link-solid:before
	content: $icon-link-solid
.icon-simulation-icon:before
	content: $icon-simulation-icon
.icon-edit-icon:before
	content: $icon-edit-icon
.icon-preloader:before
	content: $icon-preloader
